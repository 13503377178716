import { validations, fetchApi, addListenerMulti } from '../common'

export const NewCustomer = async () => {
  const form = document.getElementById("signup-form")
  const inputs = form.querySelectorAll(".input-validate")
  const postalcode = form.querySelector("#customer_postcode")
  const checker = form.querySelector("#privacy")
  const email = form.querySelector("#email-validate")
  let emailInput = email ? [await checkEmailExits(email.value)] : [true]
  let dataInput = [...Array(inputs.length)].map((e, index)=> checkValid(inputs[index]));
  let postalInput = [checkValid(postalcode)]
  let checkerInput = [checker.checked]
  if (email) {
    email.addEventListener("blur", async function() {
      const errorMess = form.querySelector("#email-error")
      const value = email.value
      let isPassEmail = false
      const  valid_validate = checkValid(email)
      if (valid_validate) {
        isPassEmail = await checkEmailExits(value)
        if (isPassEmail) {
          handleInputValid(email, errorMess)
        }  else  {
          handleInputInvalid(email, errorMess, 'メールアドレスはすでに存在しています')
        }
        emailInput[0] = isPassEmail
      } else {
        const name =  email.getAttribute('data-label')
        const message = handleError(name)
        handleInputInvalid(email, errorMess, message)
        emailInput[0] = valid_validate
      }
      checPrivacy(emailInput, dataInput, postalInput, checkerInput)
    })
  }

  for (let i = 0; i < inputs.length; i++) {
    addListenerMulti(inputs[i], 'blur change', () => {
      const errorMess = form.querySelectorAll(".error-message")
      const  is_valid = checkValid(inputs[i])
      dataInput[i] = is_valid
      if (is_valid) {
        handleInputValid(inputs[i], errorMess[i])
      }  else  {
        const name =  inputs[i].getAttribute('data-label')
        const message = handleError(name)
        handleInputInvalid(inputs[i], errorMess[i], message)
      }
      checPrivacy(emailInput, dataInput, postalInput, checkerInput)
    });
  }

  checker.addEventListener("click", function() { 
    checkerInput[0] = checker.checked
    checPrivacy(emailInput, dataInput, postalInput, checkerInput)
  });

  const events = ["input", "blur"]
  for (let i=0; i < events.length; i++) {
    postalcode.addEventListener(events[i], function() {
      const errorMess = form.querySelector("#postal-help")
      const value = postalcode.value
      const  is_valid = checkValid(postalcode)
      postalInput[0] = is_valid
      if (is_valid) {
        handleInputValid(postalcode, errorMess)
        if (value.length === 7 && events[i] === 'input') {
          handlePostalCode(value)
        }
      }  else  {
        const name =  postalcode.getAttribute('data-label')
        const message = handleError(name)
        handleInputInvalid(postalcode, errorMess,message)
      }
      checPrivacy(emailInput, dataInput, postalInput, checkerInput)
    });
  }
  const handlePostalCode = async (value) => {
    const respone = await fetchApi('GET', `/search_zip_code?code=${value}`, '')
    const data = await respone.json()
    if (data.valid && data.data) {
      const prefecture = data.data[0].table.address1
      const full_address = `${data.data[0].table.address2}${data.data[0].table.address3}` 
      document.querySelector(".input-prefecture").value = prefecture
      if (full_address) {
        document.getElementById("customer-address").value = full_address
        const errorMess = form.querySelectorAll(".error-message")
        let index = 0
        // find index of input address in array node inputs
        for (let i = 0; i < inputs.length; i++) {
          if(inputs[i].id === "customer-address") {
            index = i
          }
        }
        dataInput[index] = true
        handleInputValid(inputs[index], errorMess[index])
      }
    }
  }
}


const checkEmailExits = async (value) => {
  const respone = await fetchApi('GET', `/customers/validate_customer_email?email=${encodeURIComponent(value)}`)
  const data = await respone.json()
  return data.valid
}

const checkValid = (element) => {
  const attr = element.getAttribute('data-validation')
  const rules = attr ? attr.split(' ') : '';
  const value = element.value
  const is_valid = rules.every((r) => {
    return validations[r](value)
  })
  return is_valid
}

const handleInputValid= (ele, errorMess) => {
  ele.classList.remove("is-error")
  errorMess.classList.add('is-hidden')
}

const handleInputInvalid= (ele, errorMess, message) => {
  ele.classList.add("is-error");
  errorMess.classList.remove('is-hidden')
  errorMess.innerHTML = message
}

const checPrivacy = (emailInput, inputs, postal, check) => {
  const registerBtn = document.getElementById('register-submit');
  const all_checks = emailInput.concat(inputs).concat(postal).concat(check)
  if(all_checks.includes(false)) { 
    registerBtn.disabled = true;
    registerBtn.classList.add('btn-disable-filter');
  }
  else {
    registerBtn.disabled = false;
    registerBtn.classList.remove('btn-disable-filter');
  }
}

const handleError = (name) => {
  const rule = {
    "email": "メールアドレス",
    "password": "パスワード",
    "name": "お名前",
    "furigana_name": "フリガナ",
    "postcode": "郵便番号",
    "prefecture": "都道府県",
    "address": "住所",
    "phone": "お電話番号",
    "referral_code": "紹介コード・利用券番号"
  }
  return `${rule[name]}をご確認ください`
}