import { validations, fetchApi, checkArrSuccess } from '../common'

export const validateContact = () => {
  const btn = document.getElementById("btn-submit")
  const form = document.getElementById('contact-form')
  const inputsArr = form.querySelectorAll('.contact-input')
  const phone = document.getElementById("phone_contact")
  phone.addEventListener("blur", fomatPhone)
  let arrCheckPass = [false, true, false,false,false]
  inputsArr.forEach((element, index) => {
    element.addEventListener("input", function() {
      const messengerErr = document.querySelectorAll('.contact-message')
      const attr = element.getAttribute('data-validation');
      const rules = attr ? attr.split(' ') : [];
      rules.forEach((rule) => {
        if(!validations[rule](element.value)) {
          messengerErr[index].classList.remove('is-hidden');
          messengerErr[index].innerHTML = messageContant[element.dataset.label];
          arrCheckPass[index] = false;
        }
        else {
          arrCheckPass[index] = true;
          messengerErr[index].classList.add('is-hidden');
        }
      })
      if(checkArrSuccess(arrCheckPass)) {
        btn.disabled = false;
        btn.classList.remove('btn-disable-filter');
      }
      else {
        btn.disabled = true;
        btn.classList.add('btn-disable-filter');
      }
    })
  })
  btn.addEventListener('click', async function(e) {
    e.preventDefault();
    let formData = new FormData()
    const inputsArr = form.querySelectorAll('.contact-input')
    for(let i = 0; i < inputsArr.length; i++) {
      if(inputsArr[i].name !== "contact_us_message[email_confirm]") {
        formData.append(inputsArr[i].name, inputsArr[i].value);
      }
    }
    const respone = await fetchApi('POST', `/contact_us_messages`, formData)
    const btn = document.getElementById("btn-submit")
    const text = document.querySelector('.text-center-contact')
    if (respone.ok) {
      form.reset()
      btn.classList.add('btn-disable-filter');
      btn.disabled = true
      text.style.display = "none"
      form.innerHTML = ""
      const newNode = document.createElement("div");
      newNode.classList.add('contact-us-success')
      form.parentNode.insertBefore(newNode, form.nextSibling);
      newNode.innerHTML = eleSendContactSuccess();
      const contact = document.getElementById('contact')
      contact.scrollIntoView()
    } else {
      console.log("エラー")
    }
    return false;
  })
}

const fomatPhone = () => {
  const phone = document.getElementById("phone_contact")
  phone.value = phone.value.replace(/^(\d{3})(\d{4})(\d+$)/, "$1-$2-$3")
}

const messageContant =  {
  "name": "お名前を入力してください。",
  "email": "メールアドレスを入力してください。",
  "email_confirm": "メールアドレス確認を入力してください。",
  "message": "コンテンツを入力してください"
}

const eleSendContactSuccess = () => {
  return(`
    <div class="text-center">
      <div class="text-center contact-success">送信完了</div>
      <p class="contact-success__text">
        お問い合わせありがとうございます。<br />
        お送りして頂いた内容をご確認後、担当者より改めてご連絡させていただきます。<br />
      </p>
      <p class="contact-success__text">
        また、数日たっても返答がない場合は、何らかのトラブルでメールが届いていない可能性がありますので、お手数ですがお電話にてご連絡頂ければ幸いです。<br />
        何卒宜しくお願い致します。<br />
      </p>
    </div>
  `)
}

