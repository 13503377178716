import { validations } from '../common';

export const passwordEdit = () => {
  const form = document.getElementById("edit-password")
  const inputs = form.querySelectorAll(".input-validate")
  const valids = [...Array(inputs.length)].map(() => false)
  const help = form.querySelectorAll(".help")
  for(let i= 0; i < inputs.length; i++) {
    inputs[i].addEventListener("input", function() {
      const valid = checkValid(inputs[i])
      valids[i] = valid
      if(valid) {
        help[i].classList.add("is-hidden")
        enableButton(valids)
      }
      else {
        help[i].classList.remove("is-hidden")
        help[i].innerHTML = `パスワードをご確認ください`
        enableButton(valids)
      }
    })
  }
}

const enableButton = (valids) => {
  const form = document.getElementById("edit-password")
  const btn = form.querySelector("button")
  valids.includes(false) ? btn.classList.add("btn-disable-filter") : btn.classList.remove("btn-disable-filter");
  btn.disabled = valids.includes(false);
}

const checkValid = (element) => {
  const attr = element.getAttribute('data-validation')
  const rules = attr ? attr.split(' ') : '';
  const value = element.value
  const is_valid = rules.every((r) => {
    return validations[r](value)
  })
  return is_valid
}