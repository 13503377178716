export const Rule = () => {
  document.querySelectorAll(".tabs-li-mypage").forEach(function (el) {
    el.addEventListener("click", openTabsRule);
  });
};

const openTabsRule = (el) => {
  const tabLinks = document.querySelectorAll(".tabs-li-mypage");
  const tabContent = document.querySelectorAll(".content-rule-active");
  const btnTarget = el.currentTarget;
  const tagName = btnTarget.dataset.tagname;

  tabLinks.forEach(function (el) {
    el.classList.remove("tabs-actived");
  });

  tabContent.forEach(function (el) {
    el.classList.remove("content-rule-active");
  });
  document.querySelector("#" + tagName).classList.add("content-rule-active");
  btnTarget.classList.add("tabs-actived");
};