import Swiper from 'swiper';
import { validateContact } from './validateContact'
import { validateCalendars } from '../common'

export const HomeIndex = () => {
  let configSwiper = {
    pagination: {
      el: '.pagination-mobile',
      clickable: true,
    },
    navigation: {
        nextEl: '.button-next-mobile',
        prevEl: '.button-prev-mobile',
      },
    spaceBetween: 10,
    slidesPerView : 'auto'
  }
  let configSwiper1 = {
    pagination: {
      el: '.pagination-desktop',
      clickable: true,
    },
    navigation: {
        nextEl: '.button-next-desktop',
        prevEl: '.button-prev-desktop',
      },
    spaceBetween: 50
  }
  new Swiper('.swiper-mobile', configSwiper);
  new Swiper('.swiper-desktop', configSwiper1);
  validateContact();
  const calendarContainer = document.querySelectorAll('.calendar')
  if(calendarContainer && calendarContainer.length > 0) {
    calendarContainer.forEach((el,index) => {
      validateCalendars(el, index);
    })
  }
}