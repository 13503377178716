/* eslint-disable no-shadow */
import { HomeIndex } from '../pages/home'
import { NewWork } from '../pages/works/new_work'
import { Common } from '../pages/common'
import { NewCustomer } from '../pages/customers/new'
import { MyPage } from '../pages/mypage'
import { Rule } from '../pages/rule'
import { validate } from '../pages/users/validate'
import { passwordEdit } from '../pages/users/password_edit'

const pageLoadEvent = 'turbolinks:load';

const onPageLoad = (fn) => {
  document.addEventListener(pageLoadEvent, fn)
  document.addEventListener(pageLoadEvent, Common)
};

const initPageScripts = () => {
  const page = document.getElementsByTagName('body')[0].dataset.page
  if (!page) {
    return false;
  }
  switch (page) {
    case 'home:index':
      HomeIndex();
      break;
    case 'works:new':
      NewWork();
      break;
    case 'customers:new':
    case 'customers:edit':
      NewCustomer();
      break;
    case 'mypage:index':
      MyPage();
    case 'home:rule':
      Rule();
    case 'users:passwords:new':
    case 'devise:confirmations:new':
      validate();
      break;
    case 'users:passwords:edit':
      passwordEdit();
      break;
    default:
      break;
  }
};

onPageLoad(initPageScripts);