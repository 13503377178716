import validator from 'validator';

export const validate = () => {
  const form = document.getElementById("forgot-pasword")
  const formResend = document.getElementById("resend-confirm")
  validateEmail(form)
  validateEmail(formResend)

}

const validateEmail= (element) => {
  if (element) {
    const email = element.querySelector(".email-validate")
    email.addEventListener("input", function() {
      const valid = validator.isEmail(email.value)
      const btn = element.querySelector("button")
      if(valid) {
        btn.classList.remove("btn-disable-filter");
        btn.disabled = false;
      }
      else {
        btn.classList.add("btn-disable-filter");
        btn.disabled = true;
      }
    })
  }
}


