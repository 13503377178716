import smoothscroll from 'smoothscroll-polyfill';
import 'whatwg-fetch'
import validator from 'validator';
const moment = require('moment');
const Pikaday = require('pikaday');
const bodyScrollLock = require('body-scroll-lock');

const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
export const clearAllBodyScrollLocks = bodyScrollLock.clearAllBodyScrollLocks;

moment.locale('ja');
import Rails from '@rails/ujs';
export const Common = () => {
  checkDropdown()
  showMenu()
  scrollToTop()
  getLogin()
  checkSubmit()
  checkSuccessLogin()
  hideMenu()
  hideMenuClickContactUs()
  clearAllBodyScrollLocks() //clear disable body scroll lock function when open new page
  checkLoginUrl()
}
const hideMenuClickContactUs = () => {
  const contactUsBtn = document.getElementById("contact-us-btn");
  contactUsBtn.addEventListener("click", function() {
    closeMenuSP();
  });
}
const hideMenu = () => {
  const liArr = document.querySelectorAll(".navbar-item")
  liArr.forEach((ele) => {
    ele.addEventListener("click", function() {
      closeMenuSP();
    });
  })
}
export const closeMenuSP = () => {
  const iconMenuSp = document.getElementById("icon-menu-sp"); // element icon hambuger
  const menuSp = document.getElementById("navbarContentMenu"); // container menu sp
  if (iconMenuSp.classList.contains("is-active") && menuSp.classList.contains("is-active")) {
    iconMenuSp.classList.remove("is-active")
    menuSp.classList.remove("is-active")
    clearAllBodyScrollLocks();
  }
}
const showMenu = () => {
  const menuSp = document.getElementById("navbarContentMenu");

  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {

    // Add a click event on each of them
    $navbarBurgers.forEach( el => {
      el.addEventListener('click', () => {

        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active');
        el.classList.contains('is-active') ? disableBodyScroll(menuSp) : enableBodyScroll(menuSp)
        $target.classList.toggle('is-active');
      });
    });
  }
}

const checkDropdown = () => {
  const dropmenu  = document.getElementById('navbar-menu-account');
  if (dropmenu) {
    const dropdown = dropmenu.querySelector('#dropdown-menu');
    dropmenu.addEventListener('click', function(event) {
      event.stopPropagation();
      dropdown.classList.toggle('is-active');
    })
    window.onclick = () => {
      dropdown.classList.remove("is-active");
    }
  }
}

const scrollToTop = () => {
  smoothscroll.polyfill();
  const mybutton = document.getElementById("btn-scroll");
  const floating_call = document.getElementById('floating_call_section');
  if (floating_call) {
    floating_call.classList.add('reset-right');
  }
  window.onscroll = () => {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      mybutton.style.display = "block";
      if (floating_call) {
        floating_call.classList.remove('reset-right');
      }
    } else {
      mybutton.style.display = "none";
      if (floating_call) {
        floating_call.classList.add('reset-right');
      }
    }
  }

  mybutton.addEventListener('click', function() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  });
}

const getLogin = () => {
  const btnLogin = document.getElementById("login")
  const btnLoginSp = document.querySelectorAll(".loginsp")
  const login_modal = document.getElementById("login-modal")
  const btn_close = document.getElementById("close-login")
  const textLogin = document.querySelector(".text-login")
  const menuSp = document.getElementById("navbarContentMenu")
  const iconMenuSp = document.getElementById("icon-menu-sp")
  removeClass(btnLogin, "is-hidden", login_modal, true)
  removeClass(textLogin, "is-hidden", login_modal, true)
  addClass(btn_close, "is-hidden", login_modal, true)
  addClass(iconMenuSp, "is-hidden", login_modal)
  btnLoginSp.forEach((el) => {
    toggleClass(el, "is-hidden", login_modal, true)
    removeClass(el, "is-active", menuSp)
    removeClass(el, "is-active", iconMenuSp)
  })
}

const checkLoginUrl = () => {
  const hash = window.location.hash.substr(1);
  const with_login = hash === 'login'
  const modal_card = document.querySelector('.modal-card-body')
  if (with_login && !logined) {
    const login_modal = document.getElementById("login-modal")
    login_modal.classList.toggle('is-hidden')
    login_modal.classList.contains('is-hidden') ? clearAllBodyScrollLocks() : disableBodyScroll(modal_card)
  }
}

export const addClass = (ele, className, eleClass, enableScroll = false) => {
  if (ele) {
    ele.addEventListener("click", function() {
      eleClass.classList.add(className)
      if(enableScroll) {
        clearAllBodyScrollLocks()
      }
    })
  }
}

export const removeClass = (ele, className, eleClass, disableScroll = false) => {
  if (ele) {
    ele.addEventListener("click", function() {
      eleClass.classList.remove(className)
      if(disableScroll) {
        disableBodyScroll(eleClass)
      }
    })
  }
}
export const toggleClass = (ele, className, eleClass, lockScroll = false) => {
  const modal_card = document.querySelector('.modal-card-body')
  if (ele) {
    ele.addEventListener("click", function() {
      eleClass.classList.toggle(className)
      if(lockScroll) {
        eleClass.classList.contains(className) ? clearAllBodyScrollLocks() : disableBodyScroll(modal_card)
      }
    })
  }
}


export const validations = {
  required: (value) => {
    return !validator.isEmpty(value);
  },
  email: (value) => {
    return validator.isEmail(value)
  },
  email_confirm: (value) => {
    const email = document.getElementById('contact-email').value
    return validator.isEmail(value) && value === email
  },
  password_confirm: (value) => {
    const password = document.getElementById('reset_password').value
    return validations.password(value) && value === password
  },
  password: (value) => {
    return validator.isHalfWidth(value) && value.match(/^(?=.*[A-Za-z])(?=.*\d)[a-zA-Z\d !"#$%&'()*+,-./\\:;<=>?@[\]^_`{|}~]{4,128}$/g)
  },
  postcode: (value) => {
    return  validator.isLength(value, {max: 7}) && validator.isHalfWidth(value) && validator.isInt(value)
  },
  phone: (value) => {
    return validator.isMobilePhone(value, "any") && validator.isHalfWidth(value) && validator.isLength(value, {min: 10, max: 11})
  },
  referral_code: (value) => {
    return value.length == 0 || value.match(/^[A-Za-z0-9]{6}$/)
  },
  lengthBetween: (value, lengthMin, lengthMax) => {
    return validator.isLength(value, {min: lengthMin,max: lengthMax})
  },
  name: (value) => {
    return validations.lengthBetween(value, 1, 64)
  }
}

export const  fetchApi = async (METHOD, url, data) => {
  const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  let settings = {
    method: METHOD,
    headers: {
      'X-CSRF-Token': token
    },
  }
  if (METHOD !== 'GET') {
    settings.body = data
  }
  try {
    const fetchResponse = await fetch(url, settings);
    return fetchResponse;
  } catch (e) {
    return e;
  }
};

const checkSubmit = () => {
  const submissionFormat = 'YYYY-MM-DD'
  document.querySelectorAll(".calendar").forEach((el) => {
    el.addEventListener("submit", function() {
      el.querySelectorAll('.inp-datepicker').forEach(function(el) {
        let value = el.value
        if (!value) return
        const submissionValue = moment(value, submissionFormat).format(submissionFormat)
        let input = document.createElement("input");
        input.setAttribute("type", "hidden");
        input.setAttribute("name", el.name);
        input.value = submissionValue
        el.parentNode.insertBefore(input, el.nextSibling);
      })
    })
  });
}

const checkSuccessLogin = () => {
  const form = document.getElementById('form-login')
  const inputsArr = form.querySelectorAll('.input-form')
  const btn_login = document.getElementById("submit_login");
  let arrCheckPass = [false, false]

  inputsArr.forEach((element, index) => {
    element.addEventListener("input", function() {
      const arrValidateForm = element.getAttribute('data-validation');
      const messengerErr = document.querySelectorAll('.danger-login-form')
      const rulesValidate = arrValidateForm ? arrValidateForm.split(' ') : [];
      rulesValidate.forEach((rule) => {
        if(!validations[rule](element.value)) {
          messengerErr[index].classList.remove('is-hidden');
          arrCheckPass[index] = false;
        }
        else {
          arrCheckPass[index] = true;
          messengerErr[index].classList.add('is-hidden');
        }
      })
      if(checkArrSuccess(arrCheckPass)) {
        btn_login.classList.remove("btn-disable-filter");
        btn_login.disabled = false;
        form.addEventListener("keypress", onEnterSubmit)
      }
      else {
        btn_login.classList.add("btn-disable-filter");
        btn_login.disabled = true;
      }
    });
  })
  btn_login.addEventListener("click", async function() {
    const formData = new FormData(form)
    Rails.ajax({
      type: "POST",
      url: "/users/sign_in",
      data: formData,
      error: (repsonse) => {
        const error = form.querySelector(".title-login__error")
        error.classList.remove('is-hidden')
        error.innerHTML = repsonse
      }
    })
  });
  const onEnterSubmit = () => {
    const keycode = (event.keyCode ? event.keyCode : event.which);
    if(keycode == '13'){
      btn_login.click()
    }
  }
}

export const addListenerMulti = (element, eventNames, listener) => {
  const events = eventNames.split(' ');
  for (let i = 0; i < events.length; i++) {
    element.addEventListener(events[i], listener, false);
  }
}

export const checkArrSuccess = (arrCheckPass) => {
  return arrCheckPass.indexOf(false) === -1
}
const objI18nJa = {
  previousMonth : '',
  nextMonth     : '',
  months        : moment.monthsShort(),
  weekdays      : moment.weekdays(),
  weekdaysShort : moment.weekdaysShort()
}
export const validateCalendars = (parentEl, indexEl) => {
  const inputChecked = parentEl.querySelectorAll('.check-work-calendar');
  //create array checked input
  let checkValidateChecked = [];
  let dateOption1Selected = false;
  inputChecked.forEach(() => {
    checkValidateChecked.push(false);
  })
  // create calendar
  const pickers = [];
  const containField = parentEl.getElementsByClassName('contain-input-calendar');
  const field = parentEl.getElementsByClassName('inp-datepicker');
  let isNotReplacePassCal = false;
  let arrDateSelected = [];
  if(field.length > 0 && containField.length > 0) {
    
    Array.prototype.forEach.call(field, function(el, index) {
      const isWorkCalendar = containField[index].classList.contains('work-calendar');

      const picker = new Pikaday({
        field: containField[index],
        position: "bottom left",
        reposition: false,
        yearSuffix: "年",
        onSelect: function (date) {
          el.value = moment(date).format("YYYY年MM月DD日 (dd)");
          arrDateSelected[index] = moment(date).format("YYYY-MM-DD");
          isNotReplacePassCal = checkIsReplacePreviousDate(
            parentEl,
            arrDateSelected
          );
          if (field[index].name == "work[date_option_1]") {
            dateOption1Selected = true;
          }
          checkBtnSubmit(
            indexEl,
            checkValidateChecked,
            isNotReplacePassCal,
            dateOption1Selected
          );
        },
        setDefaultDate: true,
        defaultDate: isWorkCalendar
          ? moment().add(3, "days").toDate()
          : moment().toDate(),
        i18n: objI18nJa,
        showDaysInNextAndPreviousMonths: true,
        enableSelectionDaysInNextAndPreviousMonths: true,
        showMonthAfterYear: true,
        minDate: isWorkCalendar
          ? moment().add(3, "days").toDate()
          : moment().toDate(),
        onDraw: function () {
          // create button close
          let myButt = document.createElement("div");
          let ele = myButt.appendChild(document.createElement("div"));
          myButt.id = "btn-close";
          ele.className = "close";
          ele.appendChild(document.createTextNode("x"));
          addListenerMulti(myButt, "click touchstart ", () => {
            picker.hide();
          });
          // create div clear
          let clear = document.createElement("div");
          clear.id = "btn-clear";
          clear.className = "clear";
          clear.appendChild(document.createTextNode("削除する"));
          addListenerMulti(clear, "click touchstart ", () => {
            picker.setDate(null);
            picker.hide();
            let element = parentEl.querySelectorAll(".input-calendar")[index];
            element.value = "";
            hideMessengerCalendar(parentEl, index);
            if (element.name == "work[date_option_1]") {
              dateOption1Selected = false;
            }
            arrDateSelected[index] = null;
            isNotReplacePassCal = checkIsReplacePreviousDate(
              parentEl,
              arrDateSelected
            );
            checkBtnSubmit(
              indexEl,
              checkValidateChecked,
              isNotReplacePassCal,
              dateOption1Selected
            );
          });
          const prevButt = picker.el.querySelector(".pika-prev");
          const panel = picker.el.querySelector(".pika-title");
          prevButt.parentNode.insertBefore(myButt, prevButt);
          panel.parentNode.insertBefore(clear, panel);
        },
      });
      if (field[index].dataset.date) {
        picker.setDate(field[index].dataset.date)
      }
      pickers.push(picker);
    })
  }
  // end create calendar
  if(inputChecked && inputChecked.length > 0) {
    inputChecked.forEach((el,index) => {
      //check after render
      if(el.checked) {
        checkValidateChecked[index] = true;
      } else {
        checkValidateChecked[index] = false;
      }
      checkBtnSubmit(indexEl, checkValidateChecked ,isNotReplacePassCal, dateOption1Selected);
      el.addEventListener('change', function() {
        if(el.checked) {
          checkValidateChecked[index] = true;
        } else {
          checkValidateChecked[index] = false;
        }
        checkBtnSubmit(indexEl, checkValidateChecked ,isNotReplacePassCal, dateOption1Selected);
      });
    });
  }
}
const checkIsReplacePreviousDate = (parentEl, selectDate) => {
  const tempArrPass = previousDate ? previousDate : [];
  let countValueNotNull = 0;
  if (selectDate.every(e => e === null)) {
    return false
  }
  let countReplace = 0;
  let listMessErorCalendar = [];

  selectDate.forEach((value, index) => {
    if(tempArrPass.indexOf(value) > -1) {
      countReplace++;
      listMessErorCalendar.push(index);
    }
    if(value !== '' && value !== null) { countValueNotNull++ }
  })

  if(countReplace === countValueNotNull) {
    showMessengerCalendar(parentEl, listMessErorCalendar);
    return false;
  }
  else {
    hiddenAllMessengerCalendar(parentEl);
    return true;
  }
}
const showMessengerCalendar = (parentEl, listMessErorCalendar) => {
  const messengerOfCalendar = parentEl.querySelectorAll('.messenger-calendar');
  if(listMessErorCalendar && listMessErorCalendar.length > 0) {
    listMessErorCalendar.forEach((index) => {
      messengerOfCalendar[index].classList.remove('is-hidden');
    })
  }
}
const hideMessengerCalendar = (parentEl, indexEl) => {
  parentEl.querySelectorAll('.messenger-calendar')[indexEl].classList.add('is-hidden');
}
const hiddenAllMessengerCalendar = (parentEl) => {
  const messengerOfCalendar = parentEl.querySelectorAll('.messenger-calendar');
  if(messengerOfCalendar && messengerOfCalendar.length > 0) {
    messengerOfCalendar.forEach((el) => {
      el.classList.add('is-hidden');
    })
  }
}
const checkBtnSubmit = (indexEl, numberChecked, isNotReplace, dateOption1Selected) => {
  const btnSubmit = document.querySelectorAll('.btn-submit-calendar')[indexEl];
  if(numberChecked.indexOf(true) > -1 && isNotReplace && btnSubmit && dateOption1Selected) {
    btnSubmit.disabled = false;
    btnSubmit.classList.remove('btn-disable-filter');
  }
  else {
    btnSubmit.disabled = true;
    btnSubmit.classList.add('btn-disable-filter');
  }
}
