import { addClass } from "../common";
import validator from 'validator';
import Rails from '@rails/ujs';
const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;

export const MyPage = () => {
  document.querySelectorAll(".tabs-li-mypage").forEach(function (el) {
    el.addEventListener("click", openTabs);
  });

  document.querySelectorAll(".send-message").forEach((el) => {
    el.addEventListener("click", openMessageBox);
  });

};

const openTabs = (el) => {
  const tabContent = document.querySelectorAll(".tab-mypage-content");
  const tabLinks = document.querySelectorAll(".tabs-li-mypage");
  const btnTarget = el.currentTarget;
  const tagName = btnTarget.dataset.tagname;
  tabContent.forEach(function (el) {
    el.classList.remove("content-active");
  });

  tabLinks.forEach(function (el) {
    el.classList.remove("tabs-actived");
  });
  document.querySelector("#" + tagName).classList.add("content-active");
  btnTarget.classList.add("tabs-actived");
};

const openMessageBox = (e) => {
  const el = e.currentTarget;
  const id = el.dataset.id;
  const message_box = document.getElementById(`message-box-${id}`)
  const btn_close = document.getElementById(`close-message-${id}`);
  const modal_card = document.querySelector('.modal-card-body')
  message_box.classList.remove("is-hidden")
  disableBodyScroll(modal_card)
  const form = document.getElementById(`form-message-${id}`)
  const btn = document.getElementById(`btn-send-message-${id}`)
  addClass(btn_close, "is-hidden", message_box, true);
  checkSuccessMessage(btn, form)
  btn.addEventListener("click", senMessage)
};

const  senMessage = (e) => {
  const el = e.currentTarget;
  const id = el.dataset.id;
  const form = document.getElementById(`form-message-${id}`)
  const btn = document.getElementById(`btn-send-message-${id}`)
  const message_box = document.getElementById(`message-box-${id}`)
  const btnClose = document.getElementById("close-thanks")
  const btnCloseSp = document.getElementById("close-thanks-sp")
  const btn_close_tk = document.getElementById("close-message-thanks");
  const message_thanks = document.getElementById("message-box-thanks");
  addClass(btn_close_tk, "is-hidden", message_thanks, true);
  const formData = new FormData(form)
  Rails.ajax({
    type: "POST", 
    url: "/customer_messages",
    data: formData,
    success: () => {
      form.reset()
      btn.classList.add("btn-disable-filter")
      btn.disabled = false;
      message_box.classList.add("is-hidden")
      message_thanks.classList.remove('is-hidden')
    },
    error: (response, status) => {
      alert(`${status}: ${response}`)
    }
  })
  addClass(btnClose, "is-hidden", message_thanks, true);
  addClass(btnCloseSp, "is-hidden", message_thanks, true);
}

const checkSuccessMessage = (btn, form) => {
  const message = form.querySelector(".message_user");
  if (message) {
    message.addEventListener('input', function(e) {
      if(validator.isLength(e.target.value, {min: 1, max: 300})) {
        btn.classList.remove("btn-disable-filter");
        btn.disabled = false;
      }
      else {
        btn.classList.add("btn-disable-filter");
        btn.disabled = true;
      }
    })
  }
}